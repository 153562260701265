import React, { useEffect, useState } from "react";
import {Button, Modal, Form, Input, Select, Switch} from "antd";
import axios from "axios";
import { API_URL } from "../../../constants";
import { useSelector } from "react-redux";

const { Option } = Select;

export default function UsersModal({ setIsModalVisible, isModalVisible }) {
  const [form] = Form.useForm();
  const accessToken = useSelector(
    (state) => state.user.currentUser?.accessToken
  );
  const [options, setOptions] = useState([]);
  const [optionsServices, setOptionsServices] = useState([]);
  const [allServicesSwitch, setAllServicesSwitch] = useState(true)

  const handleCloseModal = () => {
    if (typeof isModalVisible === "object") {
      form.resetFields();
      setIsModalVisible(null);
    } else setIsModalVisible(false);
  };

  const getAllCustomers = async () => {
    try {
      const res = await axios.get(API_URL + "user/customers", {
        headers: { Authorization: "Bearer " + accessToken },
      });
      setOptions(
        res.data.map((item) => ({ value: item.CONTRACT, label: item.ABON }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  const getAllServices = async () => {
    try {
      const res = await axios.get(API_URL + "user/services", {
        headers: { Authorization: "Bearer " + accessToken },
      });
      setOptionsServices(
        res.data.map((item) => ({ value: item.SERVICEID, label: item.NAMESERVIC }))
      );
      if (typeof isModalVisible === "object") {
        console.log(allServicesSwitch, isModalVisible.services.length, res.data.length)
         if(isModalVisible.services.length !== res.data.length) {
           setAllServicesSwitch(false)
         }
      }

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (isModalVisible) {
      getAllCustomers();
      getAllServices();
      if (typeof isModalVisible === "object") {
        form.setFieldsValue(isModalVisible);
      } else {
        form.resetFields();
      }
    }
  }, [isModalVisible]);

  const postNewUser = async (values) => {
    const obj = {
      ...values,
    };
    if(allServicesSwitch === true || typeof obj.services === "undefined")  {
      obj.services = optionsServices.map(el => el.value)
      obj.allservices = true;
    } else {
      obj.allservices = false;
    }
    try {
      const res = await axios.post(API_URL + "user/us/add", obj, {
        headers: { Authorization: "Bearer " + accessToken },
      });
      if (res.status === 200) {
        form.resetFields();
        setIsModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateUser = async (values) => {
    const obj = {
      ...values,
      id: isModalVisible.id,
    };

    if(allServicesSwitch === true || typeof obj.services === "undefined")  {
      obj.services = optionsServices.map(el => el.value);
      obj.allservices = true;
    } else {
      obj.allservices = false;
    }

    try {
      const res = await axios.post(API_URL + "user/us/update", obj, {
        headers: { Authorization: "Bearer " + accessToken },
      });
      if (res.status === 200) {
        form.resetFields();
        setIsModalVisible(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title="Adăugați un utilizator nou"
      open={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
      destroyOnClose
    >
      <Form
        form={form}
        autoComplete={"off"}
        layout="vertical"
        onFinish={typeof isModalVisible === "object" ? updateUser : postNewUser}
      >
        <Form.Item
          name="nume"
          label="Nume"
          rules={[
            {
              required: true,
              message: "Vă rugăm să introduceți numele dvs",
            },
          ]}
        >
          <Input placeholder="Introduceți numele dvs" />
        </Form.Item>{" "}
        <Form.Item
          name="prenume"
          label="Prenume"
          rules={[
            {
              required: true,
              message: "Vă rugăm să introduceți prenumele dvs",
            },
          ]}
        >
          <Input placeholder="Introduceți prenumele dvs" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Vă rugăm să introduceți adresa de e-mail",
            },
          ]}
        >
          <Input placeholder="Introduceți adresa de e-mail" />
        </Form.Item>
        {isModalVisible?.type !== "edit" ? (
          <Form.Item
            name="password"
            label="Parolă"
            rules={[
              { required: true, message: "Vă rugăm să introduceți parola" },
            ]}
          >
            <Input.Password placeholder="Introduceți parola" />
          </Form.Item>
        ) : null}
        <Form.Item
          name="role"
          label="Rol"
          rules={[{ required: true, message: "Vă rugăm să selectați rolul" }]}
        >
          <Select placeholder="Selectați rolul">
            <Option value="admin">Admin</Option>
            <Option value="gestionar">Gestionar</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="customers"
          label="Clienți"
          rules={[
            { required: true, message: "Vă rugăm să selectați un client" },
          ]}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Selectați clienții"
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={options}
          />
        </Form.Item>

        <Form.Item
          name="services"
          label={
            (<><span style={{marginRight:"10px"}}>Servicii: </span> <Switch name={"allservices"} size={"small"} checkedChildren="Toate" unCheckedChildren="Manual" value={allServicesSwitch} onChange={()=> {setAllServicesSwitch(!allServicesSwitch)}} /></>)
          }
          rules={[
            // { required: true, message: "Vă rugăm să selectați un client" },
          ]}
        >
          <Select
            disabled={allServicesSwitch}
            showSearch

            mode="multiple"
            placeholder="Selectați serviciile"
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={optionsServices}
          />
        </Form.Item>
        <Form.Item
          name="modifications"
          label={(
            <span><strong>Modificări:</strong> <small>Permiteți utilizatorului acces în modulul Modificări?</small></span>
          )}
        >
          <Switch size={"small"} defaultValue={true} checkedChildren="Permis" unCheckedChildren="Interzis" />
        </Form.Item>
        <Form.Item
          name="invoice"
          label={(
            <span><strong>Factură:</strong> <small>Permiteți utilizatorului acces în modulul Factură?</small></span>
          )}
        >
          <Switch size={"small"} defaultValue={true} checkedChildren="Permis" unCheckedChildren="Interzis" />
        </Form.Item>
        <Form.Item
          name="raports"
          label={(
            <span><strong>Rapoarte:</strong> <small>Permiteți utilizatorului acces în modulul Rapoarte?</small></span>
          )}
        >
          <Switch size={"small"} defaultValue={true} checkedChildren="Permis" unCheckedChildren="Interzis" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
            Trimite
          </Button>
          <Button onClick={handleCloseModal}>Anulează</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
