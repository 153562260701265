import React, { useEffect, useState } from "react";
import {
  Card,
  Select,
  Badge,
  Button,
  Dropdown,
  List,
  Modal,
  Popover,
  Progress,
  Typography,
  Upload,
  message, Tag, Col, Row, Space,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { API_URL, host } from "../../constants";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

const { Dragger } = Upload;
const { Text } = Typography;

let interval;

const allowedFilenames = [
  "address",
  "apartament",
  "banca",
  "calculation",
  "calcultype",
  "customers",
  "indice",
  "object",
  "region",
  "serv_apart",
  "serv_furn",
  "service",
  "street",
  "luc",
];

const MultiUploadContainer = () => {
  const [blockedButtons, setBlockedButtons] = useState([]);
  const [errorBtns, setErrorBtns] = useState(null);
  const [dates, setDates] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [an, setAn] = useState(2025);
  const [ln, setLn] = useState(2);
  const [blocked, setBlocked] = useState(false);
  const accessToken = useSelector(
    (state) => state.user.currentUser?.accessToken
  );

  const handleUpload = ({ file }) => {
    if (file.status === "done") {
      message.success(`${file.name} încărcat cu succes`);
    } else if (file.status === "error") {
      message.error(`${file.name} încărcarea a eșuat.`);
    }
  };

  function handleDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  }

  function handleParsing(name) {
    try {
      axios.get(API_URL + "user/gestionar/startParse/" + name, {
        headers: { Authorization: "Bearer " + accessToken },
      });
      interval = setInterval(checkStatus, 2000);
    } catch (error) {
      console.log(error);
      message.error(error);
    }
  }

  const checkStatus = async () => {
    try {
      const res = await axios.get(API_URL + "user/gestionar/statusParser");
      const data = Object.keys(res.data).filter(
        (item) => res.data[item].parsing
      );

      const errors = Object.keys(res.data).filter(
        (item) => res.data[item].error
      );

      const dates = Object.keys(res.data).filter(
        (item) => res.data[item].lastModified
      );
      const dateMapper = {};
      dates.forEach((date) => {
        dateMapper[date] = [
          {
            key: date,
            label: moment(res.data[date].lastModified).format(
              "DD-MM-YYYY, HH:mm"
            ),
          },
        ];
      });
      setDates(dateMapper);

      if (errors.length) {
        const errorObject = {};
        errors.forEach((e) => {
          errorObject[e] = res.data[e].error;
        });
        setErrorBtns(errorObject);
      } else {
        setErrorBtns(null);
      }

      if (!data.length) {
        clearInterval(interval);
      }
      setBlockedButtons(() => {
        let arr = data.map((item) => {
          return { ...res.data[item], name: item };
        });
        return arr;
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(dates);
  }, [dates]);

  useEffect(() => {
    interval = setInterval(checkStatus, 2000);
    checkStatus();

    return () => clearInterval(interval);
  }, []);

  const deleteCurrentPlati = async () => {
    try {
      const res = await axios.get(
        API_URL + "user/gestionar/reset/CurrentPayed",
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      message.success("Plățile sunt șterse cu succes!");
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      setIsOpen(false);
    }
  };

  const handleDelete = () => {
    setIsOpen(true);
  };
  const handleDownloadMods = async () => {
    try {
      const res = await axios.get(
        API_URL + "user/gestionar/download/modificari/" + ln + "/" + an,
        {
          headers: { Authorization: "Bearer " + accessToken },
          responseType: "blob",
        }
      );
      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "modificari.zip");

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      message.success("Modificări descărcate cu succes!");
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  };
  const handleDownloadIndice = async () => {
    try {
      const res = await axios.get(
        API_URL + "user/gestionar/download/indici/" + ln + "/" + an,
        {
          headers: { Authorization: "Bearer " + accessToken },
          responseType: "blob",
        }
      );
      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "indici.zip");

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      message.success("Indici descărcate cu succes!");
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  };
  const handleUnlockPages = async () => {
    try {
      const res = await axios.get(
        API_URL + "user/gestionar/modificari/removeBlockage"
      );
      console.log({ res });
      if (res.status === 200) {
        await getBlockStatus();
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const handleBlockPages = async () => {
    try {
      const res = await axios.get(
        API_URL + "user/gestionar/modificari/setBlockage"
      );
      console.log({ res });
      if (res.status === 200) {
        await getBlockStatus();
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const getBlockStatus = async () => {
    try {
      const res = await axios.get(
        API_URL + "gestionar/modificari/blockageStatus"
      );
      console.log({ res });
      setBlocked(res.data.blocked);
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    getBlockStatus();
  }, []);

  const handleDownloadNume = async () => {
    try {
      const res = await axios.get(
        API_URL + "user/gestionar/download/modificarinume/" + ln + "/" + an,
        {
          headers: { Authorization: "Bearer " + accessToken },
          responseType: "blob",
        }
      );
      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "nume.zip");

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      message.success("Numele descărcate cu succes!");
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  };

  const handleDownloadLUC = async () => {
    try {
      const res = await axios.get(
        API_URL + "user/gestionar/download/luc/" + ln + "/" + an,
        {
          headers: { Authorization: "Bearer " + accessToken },
          responseType: "blob",
        }
      );
      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "luc.zip");

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      message.success("LUC descărcate cu succes!");
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  };

  return (
    <div className="d-flex gap-5 flex-xl-nowrap flex-wrap">
      <Dragger
        multiple
        onChange={handleUpload}
        // onRemove={handleRemove}
        onDrop={handleDrop}
        action={host + "/import/upload"}
        name="files"
        style={{ marginBottom: "16px" }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Faceți clic sau trageți fișierul în această zonă pentru a-l încărca
        </p>
        <p className="ant-upload-hint">
          Suport pentru o singură încărcare sau o încărcare în masă. Este strict
          interzisă încărcarea datelor companiei sau a altor fișiere interzise.
        </p>
      </Dragger>
      <div className="">
        <Card title={"Sold la zi / Plati curente"}>
          <span>În data de 5 a fiecărei luni, în compartimentul "Sold la zi", datele din tabel se șterg automat.</span>
          <br/>
          <span>Fișierul "dbf" care răspunde de acest compartiment este: <strong>"banca.dbf"</strong>.</span>
          <br/>
          <span>La interacțiunea cu butonul <strong>"Șterge Plăți Curente"</strong>, toate datele aferente acestui compartiment vor fi șterse, iar coloana <strong>"achitat"</strong> va fi setată la 0 (zero) în <strong>Sold la zi</strong>.</span>
          <hr/>
          <Button
            type="primary"
            variant="solid"
            color="danger"
            size={"small"}
            disabled={blockedButtons.length}
            onClick={handleDelete}
            className="danger-button"
          >
            Sterge Plati Curente (Banca)
          </Button>

        </Card>
        <Card title={"Access Modificari"}>
          <span>In data de 1 a fiecarei luni, compartimentul "Modificari" va fi dezactivat automat</span>
          <div>
            <span>Status: </span>{blocked ? (<Tag color="#f50">Blocat</Tag>) : (<Tag color="#87d068">Activ</Tag>)}
          </div>
          <hr/>
          <div>
            {blocked ? (<Button
              type="primary"
              variant="solid"
              color="success"
              size={"small"}
              // disabled={blockedButtons.length}
              onClick={handleUnlockPages}
              className="unlock-button"
            >
              Deblocheaza modificari
            </Button>) : (

              <Button
                type="primary"
                size={"small"}
                variant="solid"
                color="success"
                // disabled={blockedButtons.length}
                onClick={handleBlockPages}
                className="danger-button"
              >
                Blocheaza modificari
              </Button>
            )}
          </div>
        </Card>
        <hr />
        <Card title={"Descarcare modificari"}>
          <Row>
            <Col>
              <Space>
                <Select
                  placeholder={"Selecteaza luna"}
                  onSelect={(el) => setLn(el)}
                  options={[...Array(12)].map((_, i) => ({
                    value: `${i + 1}`,
                    label: `${i + 1}`,
                  }))}
                />
                <Select
                  placeholder={"Selecteaza anul"}
                  onSelect={(el) => setAn(el)}
                  options={["2024", "2025"].map((year) => ({
                    value: year,
                    label: year,
                  }))}
                />
              </Space>
            </Col>

          </Row>
          <hr />
          <div className="d-flex flex-column gap-3 p-2">
            <Space>
              <Button
                type="primary"
                variant="solid"
                disabled={blockedButtons.length}
                onClick={handleDownloadMods}
              >
                Descarca Modificari
              </Button>
              <Button
                type="primary"
                variant="solid"
                disabled={blockedButtons.length}
                onClick={handleDownloadIndice}
              >
                Descarca Indici
              </Button>
              <Button
                type="primary"
                variant="solid"
                disabled={blockedButtons.length}
                onClick={handleDownloadNume}
              >
                Descarca Nume
              </Button>
              <Button
                type="primary"
                variant="solid"
                disabled={blockedButtons.length}
                onClick={handleDownloadLUC}
              >
                Descarca LUC
              </Button>
            </Space>

          </div>
        </Card>
        <hr />
        <Card title={"Parsing date"}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "4px",
              maxWidth: 400,
            }}
          >
            {allowedFilenames.map((filename, index) => (
              <Badge key={index} dot={errorBtns && errorBtns[filename]}>
                <Popover
                  trigger={"hover"}
                  title={
                    errorBtns && errorBtns?.[filename] ? (
                      <div style={{maxWidth: "500px"}}>
                        <h6>
                          A apărut o eroare în înregistrarea cu CODID{" "}
                          {errorBtns[filename]?.item.CODID}
                        </h6>
                        <p>{errorBtns[filename]?.err}</p>
                      </div>
                    ) : null
                  }
                >
                  <Dropdown.Button
                    type="primary"
                    disabled={blockedButtons.length}
                    onClick={() => handleParsing(filename)}
                    menu={{items: dates?.[filename] || []}}
                    style={{width: 200, display: "flex", alignItems: "stretch"}}
                    className="dropdown-parsing"
                  >
                    {filename}
                  </Dropdown.Button>
                </Popover>
              </Badge>
            ))}
          </div>

          {blockedButtons.length ? (
            <List
              dataSource={blockedButtons}
              size="small"
              renderItem={(item) => (
                <List.Item style={{ borderBottom: "1px solid #f0f0f0" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "stretch",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <Text style={{ wordBreak: "keep-all" }}>{item.name}</Text>

                      <Text
                        type={parseInt(item.status) > 50 ? "success" : "warning"}
                      >
                        {item.status ?? 0}%
                      </Text>
                    </div>
                    <Progress
                      percent={item.status ?? 0}
                      size="default"
                      strokeColor={
                        parseInt(item.status) > 50 ? "#52c41a" : "#faad14"
                      }
                      showInfo={false} // Hides the percentage inside the bar
                      style={{ flex: 1, margin: 0 }}
                    />
                  </div>
                </List.Item>
              )}
            />
          ) : null}
        </Card>
      </div>
      <Modal
        open={isOpen}
        onOk={deleteCurrentPlati}
        onCancel={() => setIsOpen(false)}
      >
        Doriți să ștergeți toate plățile curente?
      </Modal>
    </div>
  );
};

export default MultiUploadContainer;
