import { Button, Table } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../constants";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import UsersModal from "./UsersModal";
import PasswordModal from "./PasswordModal";
import DeleteModal from "./DeleteModal";

const UsersTable = ({ isModalVisible, setIsModalVisible }) => {
  const [users, setUsers] = useState([]);
  const token = useSelector((state) => state.user.currentUser?.accessToken);

  const getUsers = async () => {
    try {
      const res = await axios.get(API_URL + "user/us/list", {
        headers: { Authorization: "Bearer " + token },
      });
      console.log(res.data);
      setUsers(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isModalVisible) getUsers();
  }, [isModalVisible]);
  const translations = {
    invoice: "Factura",
    modifications: "Modificări",
    raports: "Rapoarte"
  };

  const mainColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nume",
      dataIndex: "nume",
      key: "nume",
    },
    {
      title: "Prenume",
      dataIndex: "prenume",
      key: "prenume",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "APC-uri",
      key: "userCustomersCount",
      render: (_, record) => record.UserCustomers.length,
    },
    {
      title: "Servicii",
      key: "userServiceCount",
      render: (_, record) => record.UserServices.length,
    },

    {
      title: "Permisiuni",
      key: "userPermisions",
      render: (_, record) => {
        return  Object.keys(record)
          .filter(key => record[key] && translations[key])  // Verificăm dacă există în translations
          .map(key => translations[key])
          .join(", ");

      },
    },
    {
      title: "Actions",
      key: "edit",
      width: "50px",
      render: (_, record) => (
        <div className="d-flex gap-2 align-items-center">
          <Button className="d-flex align-items-center" type="primary" onClick={() => handleEdit(record)}>
            <EditOutlined />
          </Button>
          <Button className="d-flex align-items-center" type="primary" onClick={() => handleEditPassword(record)}>
            <UserSwitchOutlined />
          </Button>
          <Button className="d-flex align-items-center" type="primary" onClick={() => handleDelete(record)}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const handleEdit = (record) => {
    setIsModalVisible({
      type: "edit",
      id: record.id,
      email: record.email,
      nume: record.nume,
      prenume: record.prenume,
      role: record.role.toLowerCase(),
      customers: record.UserCustomers.map((uc) => uc.customerId),
      services: record.UserServices.map((uc) => uc.SERVICEID),
      allservices: record.allservices,
      modifications: record.modifications,
      invoice:record.invoice,
      raports:record.raports,
    });
  };

  const handleEditPassword = (record) => {
    setIsModalVisible({
      type: "password",
      id: record.id,
      nume: record.nume,
      prenume: record.prenume,
    });
  };

  const handleDelete = (record) => {
    setIsModalVisible({
      type: "delete",
      id: record.id,
      nume: record.nume,
      prenume: record.prenume,
    });
  };

  useEffect(() => {
    console.log({ isModalVisible });
  }, [isModalVisible]);

  const expandedRowRender = (record) => {
    const expandedColumns = [
      {
        title: "CONTRACT",
        dataIndex: ["Customer", "CONTRACT"],
        key: "CONTRACT",
      },
      {
        title: "DT_CONT",
        dataIndex: ["Customer", "DT_CONT"],
        key: "DT_CONT",
        render: (text) => (
          <span>
            {text ? moment(text).format("DD-MM-YYYY, HH:mm") : "Nu există date"}
          </span>
        ),
      },
      {
        title: "ABON",
        dataIndex: ["Customer", "ABON"],
        key: "ABON",
      },
      {
        title: "ADR_AB",
        dataIndex: ["Customer", "ADR_AB"],
        key: "ADR_AB",
      },
      {
        title: "KF_AB",
        dataIndex: ["Customer", "KF_AB"],
        key: "KF_AB",
      },
      {
        title: "TEL_AB",
        dataIndex: ["Customer", "TEL_AB"],
        key: "TEL_AB",
      },
    ];

    return (
      <Table
        columns={expandedColumns}
        dataSource={record.UserCustomers}
        pagination={false}
        rowKey={(record) => record.Customer.CONTRACT}
      />
    );
  };

  return (
    <>
      <Table
        columns={mainColumns}
        dataSource={users}
        expandable={{ expandedRowRender }}
        rowKey="id"
      />
      {isModalVisible?.type === "edit" ? (
        <UsersModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      ) : null}

      {isModalVisible?.type === "password" ? (
        <PasswordModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      ) : null}

      {isModalVisible?.type === "delete" ? (
        <DeleteModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      ) : null}
    </>
  );
};

export default UsersTable;
