import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import axios from "axios";
import { API_URL } from "../../../constants";
import { useSelector } from "react-redux";

export default function PasswordModal({ setIsModalVisible, isModalVisible }) {
  const [form] = Form.useForm();
  const accessToken = useSelector(
    (state) => state.user.currentUser?.accessToken
  );

  const handleCloseModal = () => {
    if (typeof isModalVisible === "object") {
      form.resetFields();
      setIsModalVisible(null);
    } else setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      if (typeof isModalVisible === "object") {
        form.setFieldsValue(isModalVisible);
      } else {
        form.resetFields();
      }
    }
  }, [isModalVisible]);

  const changePassword = async (values) => {
    const obj = { ...values, id: isModalVisible.id };
    try {
      const res = await axios.post(API_URL + "user/us/updatePassword", obj, {
        headers: { Authorization: "Bearer " + accessToken },
      });
      if (res.status === 200) {
        form.resetFields();
        setIsModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={`Modificare parolă pentru ${isModalVisible.nume} ${isModalVisible.prenume}`}
      open={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={changePassword}>
        <Form.Item
          name="password"
          label="Parolă"
          rules={[
            { required: true, message: "Vă rugăm să introduceți parola" },
          ]}
        >
          <Input.Password placeholder="Introduceți parola" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
            Trimite
          </Button>
          <Button onClick={handleCloseModal}>Anulează</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
