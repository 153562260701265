import axios from "axios";

//host address

export let host =
  process.env.REACT_APP_BASE_URL ||
  (process.env.NODE_ENV === "production"
    ? "https://api-gestionar.invoicer.md"
    : "http://localhost:5002");

export const API_URL = `${host}/api/v1/`;

//download methods

export const getDailyPlati = async (minDate, maxDate) => {
  try {
    const response = await axios.post(
      API_URL + "user/plati",
      { minDate, maxDate },
      { responseType: "blob" }
    );

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "plati.zip");

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};
export const getDailyIndice = async (minDate, maxDate) => {
  try {
    const response = await axios.post(
      API_URL + "user/indice",
      { minDate, maxDate },
      { responseType: "blob" }
    );

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "indice.zip");

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};
export const getDailyLUC = async (minDate, maxDate) => {
  try {
    const response = await axios.post(
      API_URL + "user/luc",
      { minDate, maxDate },
      { responseType: "blob" }
    );

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "luc.zip");

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};

export const paymentPaid = async (data) => {
  return await axios.post(API_URL + "payment/paid", data);
};
export const lastDoc = async () => {
  return await axios.get(API_URL + "user/lastDoc");
};

export const getIndice = async (data) => {
  return await axios.post(API_URL + "user/getIndice", data);
};
