import React from "react";
import { Button, Modal } from "antd";
import axios from "axios";
import { API_URL } from "../../../constants";
import { useSelector } from "react-redux";

export default function DeleteModal({ setIsModalVisible, isModalVisible }) {
  const accessToken = useSelector(
    (state) => state.user.currentUser?.accessToken
  );

  const handleCloseModal = () => {
    if (typeof isModalVisible === "object") {
      setIsModalVisible(null);
    } else setIsModalVisible(false);
  };

  const deleteUser = async () => {
    const obj = { id: isModalVisible.id };
    try {
      const res = await axios.post(API_URL + "user/us/delete", obj, {
        headers: { Authorization: "Bearer " + accessToken },
      });
      if (res.status === 200) {
        setIsModalVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={`Șterge utilizatorul ${isModalVisible.nume} ${isModalVisible.prenume}`}
      open={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
      destroyOnClose
    >
      <p>Chiar doriți să ștergeți un utilizator?</p>
      <Button
        type="primary"
        htmlType="submit"
        onClick={deleteUser}
        style={{ marginRight: 8 }}
      >
        Da
      </Button>
      <Button onClick={handleCloseModal}>Anulează</Button>
    </Modal>
  );
}
